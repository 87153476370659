import { HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError, timer } from 'rxjs';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { Init } from '../interfaces/init';
import { Estorage } from './storage';
import { Config2 } from '../interfaces/config';
import { catchError, delayWhen, map, retryWhen } from 'rxjs/operators';

export default class Ajax2 {

    private init!: Init;
    private config: Config2 = {
        alert_primary: {
            title: 'Cargando datos',
            html: '',
            icon: 'info'
        },
        alert_secundary: {
            title: 'Reintentar de nuevo ?',
            html: '',
            icon: 'question',
        },
        alert_success: {
            title: 'Los Datos se cargaron con exito',
            html: '',
            toast: false,
            position: 'center'
        },
        alert_success_waiting_list: {
            title: 'Su peticion fue guardada en la lista de espera',
            html: '',
            toast: false,
        },
        alert_waiting_list: {
            title: 'Ocurrio un error',
            html: 'Le gustaria guardar la peticion en una lista de espera ?',
        },
        alert_error: {
            title: 'Error al consultar los datos',
            html: '',
            icon: 'error',
        },
        alert_internet: {
            title: 'Conexion fallida',
            html: 'Por favor verifique su conexion a internet',
            icon: 'warning',
            toast: false
        },
        retry_requests: 3,
        delay_request: 12000,
        alerts: true,
        retry: false,
        number_of_failures_attempts: 4,
        retry_time: 6000,
        authenticate: true,
        use_socket: environment.use_socket,
        validate: this.validate,
        save_all: true,
        params: null,
        time_update: 86400000,//24H
        force_update: false,
        force_send: false,
        send_category: '',
        skip_url: false
    };

    storage: Estorage | null = null;
    private retry_count: number = 0;
    private number_of_failures: number = 0;
    private url: string;

    constructor(init: Init, config?: Config2) {
        this.init = init;
        if (typeof this.init.httpOptions === 'undefined') {
            this.init.httpOptions = {
                headers: new HttpHeaders()
            };
        } else {
            if (typeof this.init.httpOptions.headers === 'undefined') {
                this.init.httpOptions.headers = new HttpHeaders();
            }
        }
        this.setConfig(config);
        if (this.config.authenticate) {
            const headers = this.init.auth.getHeaders();
            if (headers) {
                const keys = headers.keys();
                for (const key of keys) {
                    this.init.httpOptions.headers = this.init.httpOptions.headers.set(key, headers.get(key));
                }
            }
        }
        this.storage = new Estorage(this.config.params, this.config.time_update, this.config.force_update, this.config.save_all, this.init.auth);
    }

    public setConfig(config?: Config2) {
        if (config) {
            this.config = {
                ...this.config,
                ...config,
                alert_primary: {
                    ...this.config.alert_primary,
                    ...config.alert_primary
                },
                alert_secundary: {
                    ...this.config.alert_secundary,
                    ...config.alert_secundary
                },
                alert_internet: {
                    ...this.config.alert_internet,
                    ...config.alert_internet
                },
                alert_error: {
                    ...this.config.alert_error,
                    ...config.alert_error
                },
                alert_success: {
                    ...this.config.alert_success,
                    ...config.alert_success
                },
                alert_success_waiting_list: {
                    ...this.config.alert_success_waiting_list,
                    ...config.alert_success_waiting_list
                },
                alert_waiting_list: {
                    ...this.config.alert_waiting_list,
                    ...config.alert_waiting_list
                }
            }
        }
    }

    public call(): Promise<any> {
        return new Promise((resolve, reject)=> {
            this.not_internet(resolve, reject);
        });
    }

    private not_internet(resolve: any, reject: any) {
        if (typeof this.url === 'undefined') {
            this.url = this.init.auth.router.url;
        }
        if (this.init.auth.router.url == this.url || this.config.skip_url) {
            if (typeof navigator !== 'undefined' && typeof navigator.onLine !== 'undefined' && !navigator.onLine) {
                if (this.config.alerts) {
                    Swal.fire({
                        icon: this.config.alert_internet.icon,
                        title: this.config.alert_internet.title,
                        html: this.config.alert_internet.html,
                        showConfirmButton: true,
                        showDenyButton: true,
                        confirmButtonText: 'Reintentar',
                        preConfirm: async () => {
                            this.not_internet(resolve, reject);
                        },
                    });
                } else {
                    const time = new Promise(resolve => setTimeout(resolve, this.config.retry_time));
                    time.then(() => {
                        this.sum_time();
                        this.not_internet(resolve, reject);
                    });
                }
            } else {
                if (this.config.alerts) {
                    this.init_process_alert(resolve, reject);
                } else {
                    this.init_process(resolve, reject);
                }
            }
        } else {
            reject('Cambio de url');
        }
    }

    private sum_time() {
        if (this.config.retry_time <= environment.max_time_request) {
            this.config.retry_time = this.config.retry_time * 2;
            this.config.retry_requests = this.config.retry_requests * 2;
        }
    }

    private init_process_alert(resolve: any, reject: any): void {
        Swal.fire({
            title: this.config.alert_primary.title,
            html: this.config.alert_primary.html,
            showCancelButton: false,
            showConfirmButton: false,
            showLoaderOnConfirm: false,
            icon: this.config.alert_primary.icon,
            allowOutsideClick: () => !Swal.isLoading(),
            backdrop: true,
            willOpen: async () => {
                if (typeof this.config.titles !== 'undefined') {
                    setTimeout(() => {
                        this.config.titles.loadingbar = true;
                    }, 1);
                }
                Swal.showLoading();
                try {
                    resolve(await this.process());
                    Swal.fire({
                        icon: "success",
                        title: this.config.alert_success.title,
                        html: this.config.alert_success.html,
                        showConfirmButton: false,
                        timer: 2500,
                        timerProgressBar: true,
                        toast: this.config.alert_success.toast,
                        position: this.config.alert_success.position,
                        didOpen: (toast) => {
                            toast.onmouseenter = Swal.stopTimer;
                            toast.onmouseleave = Swal.resumeTimer;
                        }
                    });
                } catch (error) {
                    console.log(error);
                    Swal.hideLoading();
                    Swal.fire({
                        title: typeof error.validate !== 'undefined'?this.config.force_send?this.config.alert_waiting_list.title:this.config.alert_secundary.title:this.config.alert_error.title,
                        html: typeof error.validate !== 'undefined'?this.config.force_send?this.config.alert_waiting_list.html:this.config.alert_secundary.html:this.config.alert_error.html,
                        icon: typeof error.validate !== 'undefined'?this.config.alert_secundary.icon:this.config.alert_error.icon,
                        confirmButtonText: this.config.force_send?'Guardar':'Reintentar',
                        showCancelButton: false,
                        showDenyButton: typeof error.validate !== 'undefined'?true:false,
                        showConfirmButton: typeof error.validate !== 'undefined'?true:false,
                        timer: typeof error.validate !== 'undefined'?null:3000,
                        timerProgressBar: typeof error.validate !== 'undefined'?false:true,
                        preConfirm: async () => {
                            if (this.config.force_send) {
                                this.storage.list_urls(this.init, this.config);
                                Swal.fire({
                                    icon: "success",
                                    title: this.config.alert_success_waiting_list.title,
                                    html: this.config.alert_success_waiting_list.html,
                                    showConfirmButton: false,
                                    timer: 2500,
                                    timerProgressBar: true,
                                    toast: this.config.alert_success_waiting_list.toast,
                                    didOpen: (toast) => {
                                        toast.onmouseenter = Swal.stopTimer;
                                        toast.onmouseleave = Swal.resumeTimer;
                                    }
                                });
                            } else {
                                this.not_internet(resolve, reject);
                            }
                        },
                        preDeny: async () => {
                            reject('Cancelado por el usuario');
                        }
                    });
                    this.errorResponse(error);
                } finally {
                    if (typeof this.config.titles !== 'undefined') {
                        this.config.titles.loadingbar = false;
                    }
                }
            },
        }).then(((result: any)=> {
        }));
    }

    private async init_process(resolve: any, reject: any) {
        try {
            const response = await this.process();
            resolve(response);
        } catch (error) {
            if (this.config.retry && typeof error.validate !== 'undefined') {
                if (this.number_of_failures <= this.config.number_of_failures_attempts) {
                    await new Promise(resolve => setTimeout(resolve, this.config.retry_time));
                    this.number_of_failures += 1;
                    this.not_internet(resolve, reject);
                } else {
                    this.sum_time();
                    this.number_of_failures = 0;
                    this.not_internet(resolve, reject);
                }
            } else {
                reject(error);
            }
        }
    }

    private process(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.storage.get_params().then(cache => {
                resolve(cache);
            }).catch(() => {
                if (this.config.use_socket && this.init.auth.statusSocket) {
                    this.socket(resolve, reject);
                } else {
                    this.fetch_ajax().pipe(
                        map(value => {
                            if (value >= this.config.retry_requests) {
                              throw value;
                            }
                            return value;
                        }),
                        retryWhen(
                            errors => {
                                return errors.pipe(
                                    delayWhen((val) => {
                                        this.retry_count += 1;
                                        if (this.retry_count >= this.config.retry_requests) {
                                            return throwError(val);
                                        }
                                        return timer(this.config.delay_request)
                                    })
                                )
                            }
                        ),
                        catchError(_=> {this.retry_count = 0; _.validate = true; reject(_); return of();})).subscribe(async (result: any)=>{
                            try {
                                if(this.init.auth.isLogin()){
                                    await this.init.auth.validate_ajax(result);
                                }
                                const resp = await this.config.validate(result);
                                this.storage.set_params(resp);
                                resolve(resp);
                            } catch (error) {
                                reject(error);
                            }
                        }
                    );
                }
            });
            
        });
    }

    socket(resolve: any, reject: any) {
        if (this.init.auth.socket) {
            this.init.auth.socket.st.emit(this.init.url.url(), this.init.body, async (resp: any) => {
                if (resp.success) {
                    resolve(resp);
                } else {
                    try {
                        const result = await this.config.validate(resp);
                        this.storage.set_params(result);
                        resolve(result);
                    } catch (error) {
                        error.validate = true;
                        reject(error);
                    }
                }
            });
        }
    }

    validate(resp: any): Promise<any> {
        return new Promise((resolve, reject) => {
            if (typeof resp.success !== 'undefined' && resp.success) {
                resolve(resp);
            } else {
                if (typeof resp.message !== 'undefined') {
                    reject(resp);
                } else {
                    reject('Error Desconocido');
                }
            }
        });
    }

    private errorResponse(error: any) {
        if (typeof error.status === 'undefined') { Swal.showValidationMessage(`Ocurrio un error: ${error.message}`)
        } else {Swal.showValidationMessage(`Estatus: ${error.status} Error: ${error.message}`)}
    }

    fetch_ajax(): Observable<any> {
        return new Observable((subscriber) => {
            if (environment.backend_type !== 'PYTHON') {
                this.init.httpOptions.headers = this.init.httpOptions.headers.set('Content-Type', 'application/json');
            } else {
                this.init.httpOptions.headers = this.init.httpOptions.headers.set('Content-Type', 'application/x-www-form-urlencoded');
            }
            fetch(this.getUrl(), {
                method: this.init.method,
                headers: this.get_headers(),
                body: this.getBody()
            }).then(response => response.json()).then((resp) =>{
                subscriber.next(resp);
                subscriber.complete();
            }).catch(error => {
                subscriber.error(error);
            });
        });
    }
    
    getUrl() {
        if (this.init.method === 'get') {
            return this.serializeUrl();
        } else {
            return this.init.url.url();
        }
    }

    getBody(): any | null {
        if (this.init.method !== 'get') {
            if (environment.backend_type !== 'PYTHON') {
                return JSON.stringify(this.init.body);
            } else {
                return this.serialize(this.init.body);
            }
        } else {
            return null
        }
    }

    serialize(body: any = false) {
        let url = '';
        if (body) {
            for (const key in body) {
                url = this.serializeBody(body[key], url, key, false);
            }
        }
        return url;
    }

    serializeUrl(): string {
        return `${this.init.url.url()}?${this.serialize(this.init.body)}`;
    }

    template(url: string, key: any, value: any): string {
        return url==''?`${key}=${value}`:`${url}&${key}=${encodeURIComponent(value)}`;
    }

    serializeBody(body: any, url: string, key: any, prime=true): string {
        if (typeof body === 'object') {
            for (const pk in body) {
                let k = key;
                if (prime) {
                    k = `[${key}]`;
                }
                k = `${k}[${pk}]`;
                url = this.serializeBody(body[pk], url, k, false);
            }
        } else {
            url = this.template(url, key, body);
        }
        return url
    }

    get_headers() {
        const keys = this.init.httpOptions.headers.keys();
        let header: any = {};
        for (const key of keys) {
            header[key] = this.init.httpOptions.headers.get(key);
        }
        return header;
    }
}
