import { Injectable } from '@angular/core';
import { UrListService } from 'src/app/core/url/url.service';
import { Init } from 'src/app/core/interfaces/init';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Config2 } from 'src/app/core/interfaces/config';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { session } from 'src/app/core/constantes/sessiones';
import { Cache } from 'src/app/core/utils/cache';
import { DataVelService } from './data-vel.service';
import { FormatCurrencyService } from 'src/app/core/services/format-currency.service';
import { rutasUrl } from 'src/app/core/constantes/rutas';
import { DatatempService } from 'src/app/vel/datatemp/datatemp.service';
import { CobranzasVelService } from 'src/app/core/services/cobranzas-vel.service';
import Ajax2 from 'src/app/core/utils/ajax2';

@Injectable({
  providedIn: 'root'
})
export class SyncService {
  loadingData = Swal.mixin({
    title: 'Cargando Datos. Por favor espere...',
    html: `<div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>`,
    allowOutsideClick: false,
    showConfirmButton: false
  });

  alertaSuccess: SweetAlertOptions = {
    icon: 'success',
    title: 'Datos descargados exitosamente',
    showConfirmButton: false,
    timer: 1500
  };

  constructor(
    public auth: AuthService,
    private listUrl: UrListService,
    public dataVs : DataVelService,
    public fcurrency: FormatCurrencyService,
    public cobraSv: CobranzasVelService,
    public DatatempService: DatatempService,
  ) { }

  async startSync(value, deleteData) {
    setTimeout(()=>{
      this.loadingData.fire()
    }, 1000);
    Cache.setLocal(session.descuentos, new Array());
    
    try {
      
      if (value === 'all' || value === 'customer') {
        Cache.setLocal(session.noVisitados, new Array());
        Cache.setLocal(session.visitados, new Array());
        Cache.setLocal(session.visitaActiva, {'iniciada': 3, 'url': rutasUrl.cat_visitas, 'idcliente': ""});
        await this.cobraSv.getCatCobranzas(false,{},true);
        // await this.getClientes();
        await this.getPlanificaciones();
        await this.getPlanVisitas();
        await this.getTipoClientes();
        // await this.getCuentasCobrar();
        // await this.getBancosNacionales();
        // await this.getBancosPrivados();
        Cache.setLocal(session.visitaActiva, {'iniciada': 0, 'url': '', 'idcliente': ''});
      }
      if (value === 'all' || value === 'products') {
        await this.getProductos();
      }
      // await this.getTipoCobro();
      await this.getFiltros();
      await this.getMotivoVisita();
      await this.getDescuentos();
      await this.getFormatoMonedas();
      if (deleteData) {
        if (this.auth.offonline) {
          await this.DatatempService.delete_data(['visitas']);
          //await this.DatatempService.delete_data(['pedidos', 'facturas', 'visitas']);
        } else {
          Cache.setLocal(session.pedidos, new Array());
          Cache.setLocal(session.facturas, new Array());
          Cache.setLocal(session.visitas, new Array());
        }
      }
      this.dataVs.even_sync_end.emit({});
      this.loadingData.close();
      Swal.mixin(this.alertaSuccess).fire();
    } catch (error) {
      Swal.mixin({
        icon: 'error',
        title: 'Ocurrio un error',
        text: error,
        showConfirmButton: false,
        confirmButtonText: 'Cerrar',
      }).fire();
    }
  }
  
  getDescuentos(): Promise<any> {
    return new Promise((resolve, reject) => {
      const initAjax: Init = {
        method: 'post',
        url: this.listUrl.url('getDescuentos'),
        auth: this.auth
      };
      const configAjax: Config2 = {
        alerts: false,
        retry: true,
        force_update: true,
        params: {name: session.descuentos, find: 'entities'}
      };
      const ajax = new Ajax2(initAjax, configAjax);
      ajax.call().then(resp => {
        this.dataVs.even_descuentos.emit({descuentos: resp.entities});
        resolve(resp.success);
      }).catch(error => {
        reject(error);
      });
    });
  }

  getClientes(): Promise<any> {
    return new Promise((resolve, reject) => {
      const initAjax: Init = {
        method: 'post',
        url: this.listUrl.url('getClientes_get'),
        auth: this.auth,
        body: {
          codiruta: this.auth.user.id_ruta
        }
      };
      const configAjax: Config2 = {
        alerts: false,
        retry: true,
        force_update: true,
        params: [
          {name: session.clientes, find: 'clientes'},
          {name: session.agenda, find: 'agenda'}
        ]
      };
      const ajax = new Ajax2(initAjax, configAjax);
      ajax.call().then(resp => {
        this.dataVs.even_cliente.emit({agenda: resp.agenda, clientes: resp.clientes});
        this.removeOrderInvoiceRelations();
        resolve(resp.success);
      }).catch(error => {
        reject(error);
      });
    });
  }

  removeOrderInvoiceRelations(): void {
    Cache.removeItem(session.cart_pedidos);
    Cache.removeItem(session.catalog_pedidos);
    Cache.removeItem(session.control_autoventa);
    Cache.removeItem(session.facturas_autoventa);
    //Cache.removeItem(session.pedidos);
    //Cache.removeItem(session.facturas);
  }

  getPlanificaciones(): Promise<any> {
    return new Promise((resolve, reject) => {
      const initAjax: Init = {
        method: 'post',
        url: this.listUrl.url('obtener_planificaciones'),
        auth: this.auth,
        body: {
          codiruta: this.auth.user.id_ruta
        }
      };
      const configAjax: Config2 = {
        alerts: false,
        retry: true,
        force_update: true,
        params: {name: session.planificacion, find: 'entities'}
      };
      const ajax = new Ajax2(initAjax, configAjax);
      ajax.call().then(resp => {
        this.dataVs.even_planificaciones.emit({planificaciones: resp.entities});
        resolve(resp.success);
      }).catch(error => {
        reject(error);
      });
    });
  }

  getPlanVisitas(): Promise<any> {
    return new Promise((resolve, reject) => {
      const initAjax: Init = {
        method: 'post',
        url: this.listUrl.url('obtener_planvisita'),
        auth: this.auth,
        body: {
          codiruta: this.auth.user.id_ruta
        }
      };
      const configAjax: Config2 = {
        alerts: false,
        retry: true,
        force_update: true,
        params: {name: session.plan, find: 'entities'}
      };
      const ajax = new Ajax2(initAjax, configAjax);
      ajax.call().then(resp => {
        this.dataVs.even_plan_visitas.emit({plan_visitas: resp.entities});
        resolve(resp.success);
      }).catch(error => {
        reject(error);
      });
    });
  }

  getTipoClientes(): Promise<any> {
    return new Promise((resolve, reject) => {
      const initAjax: Init = {
        method: 'post',
        url: this.listUrl.url('obtener_tipoclientes'),
        auth: this.auth,
        body: {
          codiruta: this.auth.user.id_ruta
        }
      };
      const configAjax: Config2 = {
        alerts: false,
        retry: true,
        force_update: true,
        params: {name: session.tcliente, find: 'entities'}
      };
      const ajax = new Ajax2(initAjax, configAjax);
      ajax.call().then(resp => {
        this.dataVs.even_tipo_cliente.emit({tipo_clientes: resp.entities});
        resolve(resp.success);
      }).catch(error => {
        reject(error);
      });
    });
  }
  
  getBancosNacionales(): Promise<any> {
    return new Promise((resolve, reject) => {
      const initAjax: Init = {
        method: 'post',
        url: this.listUrl.url('obtener_bancos_nacionales'),
        auth: this.auth
      };
      const configAjax: Config2 = {
        alerts: false,
        retry: true,
        force_update: true,
        params: {name: session.bancoNacional, find: 'entities'}
      };
      const ajax = new Ajax2(initAjax, configAjax);
      ajax.call().then(resp => {
        this.dataVs.even_bancos_nac.emit({bancos_nacionales: resp.entities});
        resolve(resp.success);
      }).catch(error => {
        reject(error);
      });
    });
  }

  getBancosPrivados(): Promise<any> {
    return new Promise((resolve, reject) => {
      const initAjax: Init = {
        method: 'post',
        url: this.listUrl.url('obtener_bancos_privados'),
        auth: this.auth
      };
      const configAjax: Config2 = {
        alerts: false,
        retry: true,
        force_update: true,
        params: {name: session.bancoPrivado, find: 'entities'}
      };
      const ajax = new Ajax2(initAjax, configAjax);
      ajax.call().then(resp => {
        this.dataVs.even_bancos_priv.emit({bancos_privados: resp.entities});
        resolve(resp.success);
      }).catch(error => {
        reject(error);
      });
    });
  }

  getTipoCobro(): Promise<any> {
    return new Promise((resolve, reject) => {
      const initAjax: Init = {
        method: 'post',
        url: this.listUrl.url('obtener_tipo_cobro'),
        auth: this.auth
      };
      const configAjax: Config2 = {
        alerts: false,
        retry: true,
        force_update: true,
        params: {name: session.tipoCobro, find: 'entities'}
      };
      const ajax = new Ajax2(initAjax, configAjax);
      ajax.call().then(resp => {
        this.dataVs.even_tipo_cobro.emit({tipo_cobro: resp.entities});
        resolve(resp.success);
      }).catch(error => {
        reject(error);
      });
    });
  }
  
  getFiltros(): Promise<any> {
    return new Promise((resolve, reject) => {
      const initAjax: Init = {
        method: 'post',
        url: this.listUrl.url('obtener_filtros'),
        auth: this.auth,
        body: {
          ruta: this.auth.user.id_ruta,
        }
      };
      const configAjax: Config2 = {
        alerts: false,
        retry: true,
        force_update: true,
        params: {name: session.filtros, find: 'entities'}
      };
      const ajax = new Ajax2(initAjax, configAjax);
      ajax.call().then(resp => {
        this.dataVs.even_filtros.emit({filtros: resp.entities});
        resolve(resp.success);
      }).catch(error => {
        reject(error);
      });
    });
  }

  getMotivoVisita(): Promise<any> {
    return new Promise((resolve, reject) => {
      const initAjax: Init = {
        method: 'post',
        url: this.listUrl.url('obtener_motivo_visita'),
        auth: this.auth
      };
      const configAjax: Config2 = {
        alerts: false,
        retry: true,
        force_update: true,
        params: {name: session.motivos, find: 'entities'}
      };
      const ajax = new Ajax2(initAjax, configAjax);
      ajax.call().then(resp => {
        this.dataVs.even_motivo_visita.emit({motivo_visita: resp.entities});
        resolve(resp.success);
      }).catch(error => {
        reject(error);
      });
    });
  }

  getProductos(): Promise<any> {
    return new Promise((resolve, reject) => {
      const initAjax: Init = {
        method: 'post',
        url: this.listUrl.url('obtener_productos'),
        auth: this.auth,
        body: {
          codiruta: this.auth.user.id_ruta
        }
      };
      const configAjax: Config2 = {
        alerts: false,
        retry: true,
        force_update: true,
        params: {name: session.productos, find: 'entities'}
      };
      const ajax = new Ajax2(initAjax, configAjax);
      ajax.call().then(resp => {
        this.removeOrderInvoiceRelations();
        this.dataVs.all_products = resp.all;
        this.dataVs.type_prices = resp.type_prices;
        this.dataVs.productos_autoventa = resp.prod_autoventa;
        this.dataVs.exist_productos_autoventa = resp.exist_prod_autoventa;
        this.dataVs.config_peso_unidades_pedi = resp.config_peso;
        this.dataVs.saldore_pedidos = resp.ver_saldore_pedidos;
        this.dataVs.precios_iva = resp.precios_iva;
        this.dataVs.config_bloq_pedi = resp.config_bloq_pedi;
        this.dataVs.multiplos_pedidos = resp.multiplos_pedidos;
        this.dataVs.even_productos.emit({productos: resp.entities});
        resolve(resp.success);
      }).catch(error => {
        reject(error);
      });
    });
  }

  getFormatoMonedas(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.fcurrency.getFormats(false,{},true).then(resp => {
        this.fcurrency.decimilesDefault = resp.salida.locale;
        this.fcurrency.simboloPrimera = resp.salida.mone1.simbolo+' ';
        this.fcurrency.simboloSegunda = resp.salida.mone2.simbolo+' ';
        this.dataVs.even_format_mone.emit(resp);
        resolve(resp.success);
      }).catch(error => {
        reject(error);
      });
    });
  }

}
